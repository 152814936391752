import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.code              = this.element.querySelector('input[name="planet-admin-code"]');
    this.currentTimer      = null;
    this.currentTypewriter = { timer: null, element: null };
  }

  // This method is called when the self destruct initiate button is clicked
  async initiate(event) {
    if (this.code.value === '') {
      alert('Code must be provided to destroy this element');
      return;
    }

    event.target.disabled = true;

    document.querySelector('[data-planetary-content]').innerHTML = `
      <button data-self-destruct-target="abort" class='bg-red-500 text-white p-2 mb-4 rounded-md w-full text-center mt-5'>ABORT</button>
      <div class='flex flex-col space-y-4 bg-gradient-to-br from-slate-900 to-black p-8 rounded-xl shadow-2xl' data-planetary-console>
        <code data-type-text="1"></code>
      </div>
    `;
    document.querySelector('[data-self-destruct-target="abort"]').addEventListener('click', () => this.abort());
    this._typeWriter(document.querySelector('[data-type-text="1"]'), 'Establishing connection to the server...');
    this.currentTimer = setTimeout(() => this.connectionEstablished(), 2500);
  }

  // This method is called when the self destruct abort button is clicked
  abort() {
    if (this.currentTypewriter.timer)   { clearTimeout(this.currentTypewriter.timer); }
    if (this.currentTimer)              { clearTimeout(this.currentTimer); }
    if (this.currentTypewriter.element) { this.currentTypewriter.element.innerHTML += '... !!ABORT!!'; }

    document.querySelector('[data-planetary-console]').innerHTML += `<code data-type-text="abort"></code>`;
    this._typeWriter(document.querySelector('[data-type-text="abort"]'), 'Self-destruct sequence aborted.');
    this.currentTimer = setTimeout(() => location.reload(), 2000);
  }

  async connectionEstablished() {
    document.querySelector('[data-type-text="1"]').innerHTML += ' OK';

    document.querySelector('[data-planetary-console]').innerHTML += `<code data-type-text="2"></code>`;
    this._typeWriter(document.querySelector('[data-type-text="2"]'), 'Confirming self destruction code...');

    this.currentTimer = setTimeout(async () => {
      if (await this.confirmDestroy()) {
        document.querySelector('[data-type-text="2"]').innerHTML += ' OK';
        this.destroySequence1();
      } else {
        document.querySelector('[data-type-text="2"]').innerHTML += ' FAILED';
        document.querySelector('[data-planetary-console]').innerHTML += `<code data-type-text="3"></code>`;
        this._typeWriter(document.querySelector('[data-type-text="3"]'), 'Self-destruction failed. Please try again later.');
        setTimeout(() => location.reload(), 2000);
      }
    }, 2500);
  }

  async confirmDestroy() {
    const response = await fetch(`${document.location.href}/confirm_destroy`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ planet_admin_code: this.code.value })
    });

    if (!response.ok) {
      return false;
    }

    return true;
  }

  async destroySequence1() {
    document.querySelector('[data-planetary-console]').innerHTML += `<code data-type-text="4"></code>`;
    this._typeWriter(document.querySelector('[data-type-text="4"]'), 'Collecting posts...');
    this.currentTimer = setTimeout(() => this.destroySequence2(), 2500);
  }

  async destroySequence2() {
    this.currentTypewriter.element.innerHTML += ' DONE';
    document.querySelector('[data-planetary-console]').innerHTML += `<code data-type-text="5"></code>`;
    this._typeWriter(document.querySelector('[data-type-text="5"]'), 'Collecting replies...');
    this.currentTimer = setTimeout(() => this.destroySequence3(), 2500);
  }

  async destroySequence3() {
    this.currentTypewriter.element.innerHTML += ' DONE';
    document.querySelector('[data-planetary-console]').innerHTML += `<code data-type-text="6"></code>`;
    this._typeWriter(document.querySelector('[data-type-text="6"]'), 'Collecting reactions...');
    this.currentTimer = setTimeout(() => this.destroySequence4(), 2500);
  }

  async destroySequence4() {
    this.currentTypewriter.element.innerHTML += ' DONE';
    document.querySelector('[data-planetary-console]').innerHTML += `<code data-type-text="7"></code>`;
    this._typeWriter(document.querySelector('[data-type-text="7"]'), 'Aiming laser at planetary core...');
    this.currentTimer = setTimeout(() => this.destroySequence5(), 3000);
  }

  async destroySequence5() {
    this.currentTypewriter.element.innerHTML += ' DONE';
    document.querySelector('[data-planetary-console]').innerHTML += `<code data-type-text="8"></code>`;
    this._typeWriter(document.querySelector('[data-type-text="8"]'), 'Charging laser...');
    this.currentTimer = setTimeout(() => this.destroyCountDown(), 2500);
  }

  async destroyCountDown() {
    this.currentTypewriter.element.innerHTML += ' CHARGED';
    document.querySelector('[data-planetary-console]').innerHTML += `<code data-type-text="9"></code>`;
    this._typeWriter(document.querySelector('[data-type-text="9"]'), 'Self-destructing in');
    this.currentTimer = setTimeout(() => this.destroyCountDownSequence(10), 1000);
  }

  async destroyCountDownSequence(timeLeft) {
    document.querySelector('[data-type-text="9"]').innerHTML = `Self-destructing in ${timeLeft}`;
    if (timeLeft === 0) {
      clearTimeout(this.currentTimer);
      this.destroy();
    } else {
      this.currentTimer = setTimeout(() => this.destroyCountDownSequence(timeLeft - 1), 1000);
    }
  }

  async destroy() {
    document.querySelector('[data-planetary-console]').innerHTML += '<code data-type-text="explode">☄️🪐💥</code>';

    const response = await fetch(document.location.href, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ planet_admin_code: this.code.value })
    });

    if (response.ok) {
      window.location.href = '/';
    } else {
      alert('Self-destruction failed. Please try again later.');
    }
  }

  _typeWriter(element, text, i = 0) {
    if (i < text.length) {
      element.innerHTML += text.charAt(i);
      i++;
      this.currentTypewriter = {
        element,
        timer: setTimeout(() => this._typeWriter(element, text, i), 50),
      };
    }
  }
}
