import { Controller } from "@hotwired/stimulus";
import { createConsumer } from "@rails/actioncable";

export default class extends Controller {
  connect() {
    this.planetSlugValue  = this.element.dataset.planetSlug;
    this.updatesSinceLoad = 0;

    this.channel = createConsumer().subscriptions.create(
      { channel: "PlanetChannel", slug: this.planetSlugValue },
      { received: (data) => this.renderUpdate(data) }
    );
  }

  disconnect() {
    this.channel?.unsubscribe();
  }

  renderUpdate(data) {
    if (data.action === "new") {
      const { uuid } = data;
      setTimeout(() => {
        if (!document.querySelector(`[data-uuid="${uuid}"]`)) {
          this.updatesSinceLoad += 1;
          this.element.innerHTML = `
            <div class="box box-dark box-highlight mb-8">
              <strong>${this.updatesSinceLoad} new transmission${this.updatesSinceLoad > 1 ? 's' : ''}</strong> received.
              <a onclick="window.location.reload()" style="cursor: pointer" class="link link-hover">Refresh?</a>
            </div>
          `;
        }
      }, 2000);
    }
  }
}
