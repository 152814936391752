import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "button"]

  toggleForm() {
    this.formTarget.classList.toggle("hidden")
    this.buttonTarget.textContent = this.formTarget.classList.contains("hidden") ? "Reply" : "Cancel"

    if (!this.formTarget.classList.contains("hidden")) {
      this.focusTextArea()
    }
  }

  focusTextArea() {
    const trixEditor = this.formTarget.querySelector("trix-editor")
    if (trixEditor) {
      trixEditor.focus()
    }
  }
}
