import { Controller } from "@hotwired/stimulus"
import { startCountdown } from "../entrypoints/countdown.js"
import { startFirstViewMessage } from "../entrypoints/first-view-message.js"
import { startSubscriptionBoxCta } from "../entrypoints/subscription-box-cta.js"
import { startUsernameSavedMessage } from "../entrypoints/username-saved-message.js"
import { setupReplyModals } from "../entrypoints/reply-modal.js"
import { setupClipboardCopy } from "../entrypoints/copy-to-clipboard.js"
import { setupBookmarkReminder } from "../entrypoints/bookmark-reminder.js"
import { startHighlightNewTransmissions } from "../entrypoints/highlight-new-transmissions.js"
import { setupClaimCode } from "../entrypoints/claim-code.js"

export default class extends Controller {
  connect() {
    startCountdown();
    startFirstViewMessage();
    startUsernameSavedMessage();
    setupReplyModals();
    setupClaimCode();
    setupClipboardCopy();
    setupBookmarkReminder();
    startHighlightNewTransmissions();
    startSubscriptionBoxCta();
  }
}
