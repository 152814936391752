console.log('Vite ⚡️ Rails')

// Example: Load Rails libraries in Vite.
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import * as Turbo from '@hotwired/turbo'
Turbo.start()

import "trix";
import "@rails/actiontext";
import "@hotwired/turbo-rails";

import '../controllers';
// import "../channels";

import { setupClipboardCopy } from './copy-to-clipboard';
import { setupReplyModals } from './reply-modal';
import { setupTrixEditors } from './trix-editor';

document.addEventListener('DOMContentLoaded', () => {
  setupClipboardCopy();
  setupReplyModals();
  setupTrixEditors();
});
